import React from "react";
import styled from 'styled-components';

import MainHeader from "./Header_new";
import MainFooter from "./Footer_new";
import Space from "./Space";

import "../styles/layout.css";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (this.state.scrolled && window.scrollY <= 10) {
      this.setState({ scrolled: false });
    } else if (!this.state.scrolled && window.scrollY > 10) {
      this.setState({ scrolled: true });
    }
  };

  render() {
    const {
      children,
      onHideNav,
      onShowNav,
      showNav,
      site,
      navMenuItems
    } = this.props;
    const { scrolled } = this.state;
    return (
      <Wrapper>
        <MainHeader
          navMenuItems={navMenuItems}
          site={site}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          scrolled={scrolled}
        />

        <Space />

        {children}

        <Space />

        <MainFooter site={site} />
      </Wrapper>
    );
  }
}

export default Layout;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: self-start;
  width: 100%;
  height: 100%;
  background: #00fed0;
`;