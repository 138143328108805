import React from "react";
import styled, { css } from 'styled-components';
import { Link, navigate } from "gatsby";

const doNavigate = target => {
  if (!target || !target.length) {
    return;
  }
  const internal = /^\/(?!\/)/.test(target);
  const isRepeatPrescriptions = target === 'prescriptions/repeat-prescriptions';

  if (internal || isRepeatPrescriptions) {
    navigate(target);
  } else {
    window.open(target, '_blank');
  }
};

const CTALink = props => {
  let link = props.route || props.link || "#";
  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    link = props.landingPageRoute.slug.current;
  }

  if (props.kind === "button") {
    return (
      <ButtonElement
        id="navAction"
        onClick={() => doNavigate(link)}
        isHeader={props.isHeader}
      >
        {props.title}
      </ButtonElement>
    );
  }

  // External
  if (props.link) {
    return (
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        {props.title}
      </a>
    );
  }

  return (
    <MenuLink
      to={link}
      activeStyle={{ color: "#14A58A" }}
    >
      {props.title}
    </MenuLink>
  );
};

export default CTALink;

const ButtonElement = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #424242;
    background: #ededed00;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;
    margin-top: 16px;

    @media (min-width: 600px) {
        width: auto;
    }

    @media (min-width: 1024px) {
      margin-top: 32px;
    }

    @media (min-width: 1058px) {
      ${({isHeader}) => isHeader && css`margin-left: 16px;`}
    }

    &:hover {
      border: 1px solid #14A58A;
    }

    &:active {
      transform: scale(.95);
    }

    svg {
      margin-left: 16px;
    }
`;

const MenuLink = styled(Link)`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: #424242;
  padding: 8px 16px 16px;
  transition: all 0.8s;
  width: 100%;

  &:after {
    transition: all 0.8s;
  }

  &:active, &:focus {
    color: #14A58A;
  }

  &.active {
    color: #14A58A;
  }

  &:hover {
    color: #14A58A;
    border-radius: 5px;
  }

  @media (min-width: 1024px) {
    width: auto;
    font-size: 16px;
    padding: 4px 8px 8px;
  }

  @media (min-width: 1240px) {
    font-size: 22px;
    padding: 4px 16px 8px;
  }
`;
