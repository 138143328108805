import React from 'react';
import styled from 'styled-components';

import logo from '../images/logo.svg';
import telIcon from '../images/icon-phone.svg';
import emailIcon from '../images/icon-mail.svg';
import bgImage from '../images/bg.svg';

function MainFooter({site}) {
  return (
    <Footer style={{backgroundImage: `url(${bgImage})`}}>
      <FooterInner>
        <FlexItem>
          <HeaderLink href={`tel:${site.contactNumber}`}>
            <TelIcon src={telIcon} alt={'Telephone icon'} />
            <Tel>{site.contactNumber}</Tel>
          </HeaderLink>
        </FlexItem>
        <FlexItem>
          <HeaderLink href={`mailto:${site.companyEmailAddress}`}>
            <Mail>{site.companyEmailAddress}</Mail>
            <MailIcon src={emailIcon} alt={'Email icon'} />
          </HeaderLink>
        </FlexItem>
      </FooterInner>
      <FooterContent>
        <Row cols={'4'} style={{marginBottom: '0'}}>
          <FooterNavigation>
              <h5>Bike Repairs Tamworth</h5>
          </FooterNavigation>

          <FooterNavigation>
              <h5>Bike Repairs Sutton Coldfield</h5>
          </FooterNavigation>

          <FooterNavigation>
              <h5>Bike Repairs West Midlands</h5>
          </FooterNavigation>

          <FooterNavigation>
              <h5>Bike Repairs Birmingham</h5>
          </FooterNavigation>
        </Row>
        <LogoDiv>
          <Logo src={logo} alt="logo" />
        </LogoDiv>
        <FooterText>Member Of Cycle Tech UK. The National Network of Mobile Bicycle Repair Specialists. Also find us at <a href="http://www.cycletechuk.co.uk" target="_blank">http://www.cycletechuk.co.uk</a> All rights reserved Mobile Bike Repair Ltd. 2022</FooterText>
      </FooterContent>
    </Footer>
  );
}

export default MainFooter;

const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    padding: 0 24px 0 0;
  }
`;

const Logo = styled.img`
  display: flex;
  width: 40%;
  max-width: 60px;

  @media (min-width: 1024px) {
    width: 100%;
    max-width: 100px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  margin-bottom: 32px;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    margin-top: 24px;
    max-height: initial;
  }

  @media (min-width: 1024px) {
    margin-top: 0;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #181917;
  background-size: 60%;

  @media (min-width: 1024px) {
    background-size: 25%;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1420px;
  padding: 24px 0;
`;

const FooterNavigation = styled.nav`
  display: flex;
  flex-direction: column;

  h5 {
    color: white;
    text-align: center;
    font-size: 18px;
    margin: 0 0 16px 0;
  }
`;

const FooterInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0 16px;
  transition: all 0.3s ease-in-out;
  background: #E1E1E1;
`;

const FlexItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
`;

const HeaderLink = styled.a`
  display: flex;
  align-items: center;
  line-height: 1;
`;

const TelIcon = styled.img`
`;

const Tel = styled.span`
  display: none;

  @media (min-width: 600px) {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    padding: 0 0 4px 10px;
  }
`;

const MailIcon = styled.img`
`;

const Mail = styled.span`
  display: none;

  @media (min-width: 600px) {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    padding: 0 10px 4px 0;
  }
`;

const FooterText = styled.h4`
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 55%;
  }

  a {
    color: white;
    text-decoration: underline;
  }
`;
